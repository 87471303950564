import React from 'react';
import { Button } from 'theme/Button';
import history from 'historyContainer';
import { openModal } from 'state/modals/actions';
import {
  WelcomeNavigationSection,
  ButtonRow,
} from './WelcomeNavigation.styles';

function WelcomeNavigation() {
  return (
    <WelcomeNavigationSection>
      <section id="welcomeText">
        PipeSearch has dedicated extensive time and resources, with the help of
        industry experts and technology veterans, to develop a digital product
        that meets the wants and needs of those in the OCTG and Tubular markets.
        We have created software solutions that drive efficiency,
        sustainability, quality, and transparency in the trading of quality
        surplus and OCTG products.
      </section>
      <h2
        id="welcomeTitle"
        style={{
          fontSize: '1.8rem',
          fontWeight: '500',
          lineHeight: '1rem',
          marginBottom: '8px',
        }}
      >
        Buy, Sell & Trade with PipeSearch today!
      </h2>
      <ButtonRow>
        <Button
          className="navigatingButtons"
          size="large"
          primary
          bold
          onClick={() => {
            history.push('/global-warehouse');
          }}
        >
          Search the Global Warehouse
        </Button>
        <Button
          className="navigatingButtons"
          size="large"
          primary
          bold
          onClick={(e) => {
            e.preventDefault();
            openModal({
              type: 'quickInqueryModalForReal',
              data: { isSupply: true },
            });
          }}
        >
          Submit Your Supply
        </Button>
      </ButtonRow>
    </WelcomeNavigationSection>
  );
}

export default WelcomeNavigation;
