import React from 'react';
import { HelmetWrapper, basicHelmetGenerator } from 'utility/DefaultMeta';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { LandingSection } from 'theme/Page';
import { LandingPage } from './Landing.styles';
import TeamNetworkResource from './components/TeamNetworkResource/TeamNetworkResource';
import FoundationPrinciples from './components/FoundationPrinciples/FoundationPrinciples';
import GetStartedBanner from './components/GetStartedBanner/GetStartedBanner';
import WelcomeNavigation from './components/WelcomeNavigation/WelcomeNavigation';
import LandingHero from './components/LandingHero/LandingHero';

function Landing() {
  return (
    <LandingPage>
      <HelmetWrapper
        metadata={basicHelmetGenerator({
          title: 'PipeSearch - Tomorrows Tubular Solutions Platform',
          description:
            'With PipeSearch.com, recover value for your unused OCTG inventory and fulfill critical demand.',
        })}
      />
      <LandingSection page="landing" style={{ paddingTop: '0' }}>
        <LandingHero />
      </LandingSection>
      <LandingSection
        page="landing"
        style={{ paddingBottom: '20px', paddingTop: '0' }}
      >
        <WelcomeNavigation />
      </LandingSection>
      <LandingSection page="landing" style={{ marginTop: '-15px' }}>
        <FoundationPrinciples />
      </LandingSection>
      <LandingSection page="landing" style={{ marginTop: '-15px' }}>
        <TeamNetworkResource />
      </LandingSection>
      <LandingSection page="landing" style={{ marginTop: '-15px' }}>
        <GetStartedBanner />
      </LandingSection>
    </LandingPage>
  );
}

export default PageViewWrapper(Landing, { pageName: 'Landing' });
