import React, { useState, useEffect } from 'react';
import { useBranch } from 'baobab-react/hooks';
import history from 'historyContainer';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { openModal } from 'state/modals/actions';
import { PublicItemSelectionFilters } from 'components/ItemSelectionFilters/ItemSelectionFilters';
import { getCriteriaParams } from 'state/requestForQuote/transformer_criteria';
import Icon from 'components/Icon/Icon';
import { Button } from 'theme/Button';
import { onSearchChange, setActiveRfqItem } from 'state/search/actions';
import { getLandingCount } from 'state/landing/actions';
import isMobile from 'utility/isMobile';
import { isNumber, omit, isEmpty } from 'lodash';
import { variables } from 'theme/variables';
import { getRfqMeta } from 'state/requestForQuote/actions';
import PropTypes from 'prop-types';
import {
  LandingHeroCard,
  GlobalFiltersBar,
  NewLandingHero,
  CallToActionSubHeader,
  EmptyRow,
} from './LandingHero.styles';

const { custom_blue: customBlue } = variables;

function LandingHero({ welcomeSuppliers }) {
  // Default to true to prevent showing search bar when mobile page is still loading
  const [isMobileBrowser, setIsMobileBrowser] = useState(true);

  const filtersSkeletonData = {
    od: {
      title: 'OD',
      inputType: 'FilterItemValues',
      items: [],
    },
    weight_per_foot: {
      title: '#/FT',
      inputType: 'FilterItemValues',
      items: [],
    },
    alloy: {
      title: 'Alloy (0)',
      inputType: 'FilterItemValues',
      items: [],
    },
    min_yield: {
      title: 'Yield',
      inputType: 'number',
    },
    end_finish: {
      title: 'End Finish (0)',
      inputType: 'FilterItemValues',
      items: [],
    },
  };

  const {
    landingCount,
    rfqItemFilters = filtersSkeletonData,
    rfqMetadata,
  } = useBranch({
    landingCount: ['landing', 'landing', 'result'],
    rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  // Prevent the filter search bar menus from opening at top and being hidden behind the hero
  const popperModifiers = {
    flip: {
      enabled: false,
    },
  };
  const hasRfqMetadata = !!rfqMetadata;

  function directToGlobalWarehouse() {
    history.pushWithParams(`/global-warehouse/`, {
      criteria: getCriteriaParams(),
    });
  }

  useEffect(() => {
    getLandingCount();
    setIsMobileBrowser(isMobile());
  }, []);

  useEffect(() => {
    if (hasRfqMetadata) {
      const seed = getCriteriaParams();
      setActiveRfqItem(seed);
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return welcomeSuppliers ? (
    <NewLandingHero>
      <EmptyRow />
      <CallToActionSubHeader>
        <Row className="headerTitle">
          Let Us Convert Your Idle Assets Into Working Capital Today
        </Row>
        <Row className="subheaderText">
          Ready to maximize your return on an idle asset? Tell us about it
          below. We will review and respond with customized options tailored to
          your goals.
        </Row>
        <Row className="buttonContainer">
          <Button
            className="sendSurplusButtonPulse"
            onClick={() =>
              openModal({
                type: 'quickInqueryModalForReal',
                data: { isSupply: true },
              })
            }
            size="small"
          >
            Send Us Your Surplus
          </Button>
        </Row>
      </CallToActionSubHeader>
    </NewLandingHero>
  ) : (
    <LandingHeroCard>
      <Row className="fullNavDetails">
        <Col className="heroDetails">
          <Row>
            <h1 className="heroTitle">The Global Warehouse</h1>
          </Row>
          <Row id="subtitleRow">
            <h2 className="heroSubtitle">
              Your source for High-Quality OCTG Assets
            </h2>
          </Row>
        </Col>
        <Col id="subDetailCol">
          <div className="heroSubDetails">
            <div className="fullCountText">
              <div className="heroNumbers" style={{ marginLeft: '0px' }}>
                {isNumber(landingCount?.asset_count)
                  ? landingCount.asset_count.toLocaleString('en-us')
                  : '--'}
                <Tooltip
                  type="light"
                  className="tooltipStyling"
                  placement="end"
                  refKey="ref"
                  size="medium"
                  zIndex={5}
                  trigger={
                    <Icon
                      color={customBlue}
                      fontAwesome
                      icon="info-circle"
                      fontSize="12px"
                      style={{
                        cursor: 'pointer',
                        color: customBlue,
                        position: 'relative',
                        left: '2px',
                        top: '-10px',
                      }}
                    />
                  }
                >
                  <div id="tooltipText">
                    {`The PipeSearch Global Warehouse is comprised of exclusive listings,
                          partner inventory, and assets in our network. While we only list a small
                          portion of our material, you can request an item and our team will get 
                          to work to find what you need within our web of trusted partners and
                          suppliers!`}
                  </div>
                </Tooltip>
              </div>
              <div style={{ textAlign: 'center' }}>Assets in our Network</div>
            </div>
            <div className="fullCountText">
              <div className="heroNumbers">28,000+</div>
              <div style={{ textAlign: 'center' }}>Operator Contacts</div>
            </div>
            <div className="fullCountText">
              <div className="heroNumbers">
                {landingCount?.country_count || '168+'}
              </div>
              <div style={{ textAlign: 'center' }}>Active Countries</div>
            </div>
          </div>
        </Col>
      </Row>
      {!isMobileBrowser && (
        <GlobalFiltersBar
          mainPage={
            history?.location?.pathname === '/' ||
            history?.location?.pathname === '/landing'
          }
          mobile={isMobileBrowser}
        >
          <PublicItemSelectionFilters
            style={{
              width: '100%',
              boxShadow: `0 4px 16px #c0cff7`,
              justifyContent: 'center',
              zIndex: 6,
              backgroundColor: customBlue,
            }}
            rowIndex={0}
            onChangeFilters={onSearchChange}
            filters={omit(rfqItemFilters, ['category', 'quantity'])}
            inline
            search
            toggleTable={() => directToGlobalWarehouse()}
            menuZIndex={3}
            popperModifiers={popperModifiers}
            page="home"
          />
        </GlobalFiltersBar>
      )}
    </LandingHeroCard>
  );
}

LandingHero.defaultProps = {
  welcomeSuppliers: false,
};

LandingHero.propTypes = {
  welcomeSuppliers: PropTypes.bool,
};

export default LandingHero;
