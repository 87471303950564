import styled from 'styled-components/macro';
import { Grid, Row } from '@zendeskgarden/react-grid';

export const WelcomeNavigationSection = styled(Grid)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  #welcomeText {
    max-width: 1000px;
    text-align: center;
    margin: 30px 10px;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .navigatingButtons {
    margin-top: 20px;
    min-width: 255px;
    max-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  @media (max-width: 620px) {
    #welcomeTitle {
      text-align: center;
    }
  }
`;

export const ButtonRow = styled(Row)`
  display: flex;
  flex-wrap: noWrap;
  max-width: 530px;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 666px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
`;

export default WelcomeNavigationSection;
