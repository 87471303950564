import React from 'react';
import { SectionHeader } from 'routes/Landing/Landing.styles';
import { Button } from 'theme/Button';
import history from 'historyContainer';
import { PropTypes } from 'prop-types';
import { GetStartedSection } from './GetStartedBanner.styles';

function GetStartedBanner({ sectionStyles, welcomeSuppliers }) {
  return (
    <GetStartedSection
      style={sectionStyles}
      welcomeSuppliers={welcomeSuppliers}
    >
      <SectionHeader id="inventoryTitle">
        Let&apos;s find the inventory to meet your need!
      </SectionHeader>
      <Button
        id="getStartedButton"
        size="large"
        primary
        bold
        onClick={() => {
          history.push('/global-warehouse');
        }}
      >
        Search the Global Warehouse
      </Button>
    </GetStartedSection>
  );
}

GetStartedBanner.defaultProps = {
  sectionStyles: undefined,
  welcomeSuppliers: false,
};

GetStartedBanner.propTypes = {
  sectionStyles: PropTypes.shape({}),
  welcomeSuppliers: PropTypes.bool,
};

export default GetStartedBanner;
