import React from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import { openModal } from 'state/modals/actions';
import Flex from 'styled-flex-component';
import history from 'historyContainer';
import {
  TeamNetworkResourceSection,
  TeamNetworkResourceCard,
  CardHeader,
  CardSubHeader,
  MaterialList,
} from './TeamNetworkResource.styles';

function TeamNetworkResource() {
  return (
    <TeamNetworkResourceSection>
      <TeamNetworkResourceCard>
        <Row id="descriptionRow">
          <Col id="materialTextCol">
            <CardHeader>
              We provide quality material to meet your project needs
            </CardHeader>
            <CardSubHeader>
              We supply material for a variety of projects, including:
            </CardSubHeader>
            <MaterialList>
              <li>High-pressure & High-Temperature (HPHT)</li>
              <li>Carbon Capture Utilization & Storage (CCUS & CCS)</li>
              <li>Hydrogen Sulfide (H2S)</li>
              <li>Acid Gas Injection (AGI)</li>
              <li>Geothermal</li>
              <li>Salt Water Disposal (SWD)</li>
              <li>Salt Water Injection (SWI)</li>
              <li>Water Alternating Gas (WAG)</li>
              <li>Hydrogen Wells</li>
              <li>many, many more!</li>
            </MaterialList>
          </Col>
          <Col>
            <Flex column alignCenter justifyCenter style={{ height: '100%' }}>
              <Button
                id="expertButton"
                className="resourceButton"
                size="large"
                spacingTop
                primary
                bold
                onClick={(e) => {
                  e.preventDefault();
                  openModal({
                    type: 'contactUsModal',
                  });
                }}
              >
                Speak with an Expert Now!
              </Button>
              <Button
                id="technicalResourcesButton"
                className="resourceButton"
                size="large"
                spacingTop
                primary
                bold
                onClick={() => {
                  history.push('/technical-resources');
                }}
              >
                Go to our Technical Resources!
              </Button>
            </Flex>
          </Col>
        </Row>
      </TeamNetworkResourceCard>
    </TeamNetworkResourceSection>
  );
}

export default TeamNetworkResource;
