import styled from 'styled-components/macro';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const { color_grey_700: colorGrey700 } = variables;

export const FoundationPrinciplesSection = styled(Grid)`
  width: 100%;
`;

export const FoundationPrinciplesCardRow = styled(Row)`
  display: flex;
  justify-content: center;
  flex-wrap: noWrap;
  @media (max-width: 925px) {
    flex-wrap: wrap;
  }
`;

export const FoundationPrinciplesCard = styled(Row)`
  min-height: 300px;
  width: 49%;
  min-width: 260px;
  margin-right: 1px;
  border-radius: 1em;
  margin: 10px;
  box-shadow: 0 4px 16px #c0cff7;
  display: flex;
  justify-content: center;
  align-content: space-between;
  padding: 20px;
  .cardTitleRow {
    display: flex;
    justify-content: center;
    align-content: space-between;
  }
  .descriptionRow {
    width: 92%;
    color: ${colorGrey700};
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    justify-content: center;
  }
  .cardButton {
    width: 100%;
    min-width: 300px;
    margin-top: 10px;
    font-size: 16px;
    max-height: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  #foundationPipesearchLogo {
    width: 100%;
    max-height: 100px;
    min-height: 85px;
  }
  @media (max-width: 1211px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media (max-width: 925px) {
    min-width: 460px;
    width: 100%;
  }
  @media (max-width: 600px) {
    .cardButton {
      max-width: 415px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media (max-width: 575px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (max-width: 500px) {
    min-width: 300px;
    .cardButton {
      min-width: 270px;
      font-size: 12px;
    }
    #foundationPipesearchLogo {
      max-height: 70px;
    }
  }
  @media (max-width: 425px) {
    .descriptionRow,
    .cardTitleRow {
      margin-left: 5px;
      margin-right: 5px;
    }
    .cardButton {
      max-width: 280px;
    }
  }
`;
