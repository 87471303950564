import styled from 'styled-components/macro';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
import { LG, MD } from '@zendeskgarden/react-typography';

const { custom_dark_blue: customDarkBlue, custom_grey: customGrey } = variables;

export const TeamNetworkResourceSection = styled(Grid)`
  width: 100%;
  margin-top: -30px;
`;

export const TeamNetworkResourceCard = styled(Row)`
  min-height: 300px;
  margin: 2px 0px;
  border-radius: 1em;
  box-shadow: 0 4px 16px #c0cff7;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  background-image: url('/images/global.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 50% 100%;
  #descriptionRow {
    width: 100%;
    padding: 20px;
  }
  #materialTextCol {
    min-width: 65%;
  }
  .resourceButton {
    min-width: 300px;
    max-width: 450px;
    font-size: 16px;
    max-height: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  #technicalResourcesButton {
    background-color: ${customDarkBlue} !important;
  }
  @media (max-width: 1024px) {
    #descriptionRow {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 767px) {
    background-image: none;
    #materialTextCol {
      min-width: 0px;
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    padding: 0 10px;
    #descriptionRow {
      padding: 20px 0px;
    }
  }
  @media (max-width: 425px) {
    .resourceButton {
      font-size: 12px;
      min-width: 250px;
      max-width: 300px;
    }
  }
  @media (max-width: 375px) {
    .resourceButton {
      min-width: 230px;
    }
  }
`;

export const CardHeader = styled(LG)`
  font-size: 22px !important;
  margin-bottom: 10px;
`;

export const CardSubHeader = styled(MD)`
  color: ${customGrey} !important;
  margin-bottom: 10px;
`;

export const MaterialList = styled('ul')`
  column-count: 2;
  list-style-type: disc;
  color: ${customGrey};
  margin-left: 45px;
  margin-right: 5px;
  li {
    margin-right: 5px;
  }
  @media (max-width: 768px) {
    margin-left: 25px;
  }
  @media (max-width: 400px) {
    column-count: 1;
  }
`;
