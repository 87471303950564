import React, { useState, useEffect } from 'react';
import { Row } from '@zendeskgarden/react-grid';
import Flex from 'styled-flex-component';

import { Button } from 'theme/Button';
import history from 'historyContainer';
import isMobile from 'utility/isMobile';
import {
  FoundationPrinciplesSection,
  FoundationPrinciplesCardRow,
  FoundationPrinciplesCard,
} from './FoundationPrinciples.styles';

function FoundationPrinciples() {
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  useEffect(() => {
    setIsMobileBrowser(isMobile());
  }, []);

  return (
    <FoundationPrinciplesSection isMobileBrowser={isMobileBrowser}>
      <FoundationPrinciplesCardRow>
        <FoundationPrinciplesCard>
          <Row className="cardTitleRow">
            <Row className="descriptionRow">
              <section style={{ marginTop: '10px', marginBottom: '30px' }}>
                <img
                  src="/images/pipestats.svg"
                  alt="PipeStats Logo"
                  id="foundationPipesearchLogo"
                />
              </section>
              <section>
                {' '}
                PipeStats
                <sup style={{ fontSize: '9px', paddingLeft: '1px' }}>TM</sup>
                reports provide valuable market intelligence covering demand,
                known alternatives, market trends, and pricing. We leverage this
                information to enable sellers to choose the best course for
                handeling their CRA OCTG investment.
              </section>
            </Row>
            <Flex column alignCenter justifyCenter>
              <Button
                className="cardButton"
                size="large"
                spacingTop
                primaryOutline
                bold
                onClick={() => {
                  history.push('/sellers');
                }}
              >
                Learn More
              </Button>
            </Flex>
          </Row>
        </FoundationPrinciplesCard>
        <FoundationPrinciplesCard>
          <Row className="cardTitleRow">
            <Row className="descriptionRow">
              <section style={{ margin: '10px', marginBottom: '30px' }}>
                <img
                  src="/images/pipefacts.svg"
                  alt="PipeFacts Logo"
                  id="foundationPipesearchLogo"
                />
              </section>
              <section>
                {' '}
                PipeFacts
                <sup style={{ fontSize: '9px', paddingLeft: '1px' }}>TM</sup> is
                a proprietary for-hire service that provides ultimate clarity to
                tubular product quality. PipeFacts
                <sup style={{ fontSize: '9px', paddingLeft: '1px' }}>
                  TM
                </sup>{' '}
                helps sellers increase their asset value, and it gives buyers
                confidence in the material&apos;s quality.
              </section>
            </Row>
            <Flex column alignCenter justifyCenter>
              <Button
                className="cardButton"
                size="large"
                spacingTop
                primaryOutline
                bold
                onClick={() => {
                  history.push('/buyers');
                }}
              >
                Learn More
              </Button>
            </Flex>
          </Row>
        </FoundationPrinciplesCard>
      </FoundationPrinciplesCardRow>
    </FoundationPrinciplesSection>
  );
}

export default FoundationPrinciples;
