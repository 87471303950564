import styled from 'styled-components/macro';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const {
  custom_grey: customGrey,
  custom_blue_grey: customBlueGrey,
  custom_slate: customSlate,
  custom_blue_transparent: customBlueTransparent,
} = variables;

export const LandingHeroCard = styled(Grid)`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border-radius: 1em;
  padding: 15px 20px 5px;
  box-shadow: 0 4px 16px #c0cff7;
  background-image: url(/images/transparentPipes.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 55%;
  .fullNavDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 75px;
    margin-right: 0;
  }
  .heroDetails {
    margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
    ${/* specfically for safari browser users */ ''}
    -webkit-flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .heroTitle {
    margin-top: 25px;
    font-weight: 600;
    font-size: 3rem;
  }
  .heroSubtitle {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    width: fit-content;
    color: ${customGrey};
    line-height: 25px;
    border-right: 0.15em solid ${customGrey};
    overflow: hidden;
    white-space: noWrap;
    letter-spacing: 0.03em;
    animation: typingLarge 3s steps(38, end),
      blink-caret 0.75s step-end infinite;
  }
  .heroSubDetails {
    font-size: 1rem;
    font-weight: bold;
    color: ${customGrey};
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .heroNumbers {
    font-size: 2rem;
    margin-bottom: 10px;
    color: ${customBlueGrey};
  }
  .fullCountText {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
    margin-bottom: 15px;
  }
  .tooltipStyling {
    max-width: 300px;
    border-radius: 20px;
    color: ${customSlate};
    box-shadow: 0 4px 16px #c0cff7;
    border: none;
  }
  @media (max-width: 1850px) {
    .heroSubDetails {
      width: 100%;
    }
  }
  @media (max-width: 1100px) {
    .heroSubDetails {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 1038px) {
    .fullNavDetails {
      margin-top: 30px;
    }
    .heroTitle {
      font-size: 2.5rem;
      line-height: 2.2rem;
    }
    .heroSubtitle {
      font-size: 1.2rem;
    }
    .heroNumbers {
      font-size: 1.3rem;
    }
  }
  @media (max-width: 975px) {
    .heroSubtitle {
      height: 40px;
      white-space: pre-wrap;
      border-right: none;
      animation: none;
    }
  }
  @media (max-width: 885px) {
    .fullNavDetails {
      margin-top: 0px;
    }
    .heroSubtitle {
      width: 100%;
      overflow: visible;
      white-space: wrap;
      letter-spacing: 0;
    }
    .fullCountText {
      margin-bottom: 5px;
    }
    .heroTitle {
      font-size: 2rem;
      margin-top: 0;
    }
    .heroDetails {
      margin-top: 50px;
    }
  }
  @media (max-width: 850px) {
    .fullNavDetails {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }
    .heroDetails {
      margin-left: 0px;
      ${/* specfically for safari browser users */ ''}
      -webkit-flex: 1 1 auto;
    }
  }
  @media (max-width: 768px) {
    .fullNavDetails {
      margin-top: 0;
    }
    .heroSubDetails {
      margin-top: 0px;
      font-size: 1rem;
    }
  }
  @media (max-width: 721px) {
    background-image: none;
    .heroTitle,
    .heroSubtitle {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 666px) {
    .heroDetails {
      min-width: 100%;
      margin-top: 20px;
      margin-left: 5px;
    }
    .heroSubtitle {
      margin-top: 10px;
    }
    #subDetailCol {
      display: none;
    }
  }
`;

export const GlobalFiltersBar = styled('div')`
  width: 95%;
  z-index: 5;
  margin-top: -10px;
  display: ${(props) => (props.mobile ? 'unset' : 'flex')};
  justify-content: center;
  position: relative;
  top: 60px;
  @media (max-width: 1100px) {
    margin-top: -10px;
  }
  @media (max-width: 947px) {
    margin-top: -10px;
  }
  @media (max-width: 885px) {
    margin-top: 0px;
  }
  @media (max-width: 799px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  @media (max-width: 666px) {
    margin-top: -60px;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
`;

// New Landing Styles
export const NewLandingHero = styled(Grid)`
  width: 100%;
  background-image: url(/images/pipeYard.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 60vh;
  transform: scaleX(-1);
  margin-top: -10px;
`;

export const EmptyRow = styled(Row)`
  height: 40%;
  @media (max-width: 1050px) {
    height: 0%;
  }
`;

export const CallToActionSubHeader = styled(Row)`
  transform: scaleX(-1);
  background-color: ${customBlueTransparent};
  height: 60%;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 1050px) {
    height: 100%;
  }
  .headerTitle {
    margin: 20px 30px 10px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.2rem;
  }
  .subheaderText {
    width: 40%;
    margin: 10px auto;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 90%;
      margin: 10px;
    }
  }
  .sendSurplusButton {
    max-width: 500px;
    border-radius: 20px;
  }
  .buttonContainer {
    margin-top: 10px;
  }
  .sendSurplusButtonPulse {
    margin: auto 10px;
    margin-bottom: 20px;
    min-width: 100px;
    max-height: 40px;
    min-height: 30px;
    font-weight: 700;
    color: #313133;
    background: #4fd1c5;
    background: linear-gradient(
      90deg,
      rgba(129, 230, 217, 1) 0%,
      rgba(79, 209, 197, 1) 100%
    );
    border: none;
    border-radius: 1000px;
    box-shadow: 6px 6px 20px rgba(79, 209, 197, 0.64);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: auto;
  }
`;
