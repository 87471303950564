import styled from 'styled-components/macro';
import { Grid } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const { custom_blue: customBlue } = variables;

export const GetStartedSection = styled(Grid)`
  width: 100%;
  margin-top: -30px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.welcomeSuppliers
      ? `
    justify-content: space-evenly;
    #getStartedButton {
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      border-radius: 25px;
      padding: 0 80px;
    }
    #inventoryTitle {
      font-size: 2rem !important;
      color: ${customBlue};
      font-weight: 700 !important;
    }
    @media (max-width: 620px) {
      flex-wrap: wrap;
      #inventoryTitle {
        text-align: center;
      }
      #getStartedButton {
        margin-top: 10px;
      }
    }
  `
      : `
    flex-wrap: wrap;
    flex-direction: column;
    #getStartedButton {
      margin-top: 20px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    @media (max-width: 620px) {
      #inventoryTitle {
        text-align: center;
      }
    }
  `}
`;

export default GetStartedSection;
